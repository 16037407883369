/**
 *
 * TermsPage
 *
 *
 */

import React, { memo, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Link from 'components/Elements/Link';

import SEO from 'components/seo';
import theme from 'utils/theme';

const CookiesNotice = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Cookies Notice" />
      <h1>Ripspot Cookies Notice</h1>
      <p>
        <em>Effective as of 12/15/2020</em>
      </p>
      <p>
        Our services (including our website, mobile app and email
        communications, together the “Services”) uses cookies and other device
        identification technologies (“cookies”), to provide our Services, to
        understand how they are used, and for advertising purposes. You can read
        more about the types of cookies we use, why we use them and how you can
        exercise your cookies choices below.
        <br />
        <br />
        Our website includes widgets, which are interactive mini-programs that
        run on our site to provide specific services from another company (such
        as displaying the news, opinions, music, and more). Personal
        information, such as your email address, may be collected through the
        widget. Cookies may also be set by the widget to enable it to function
        properly. Information collected by this widget is governed by the
        privacy policy of the company that created it.
      </p>
      <h2>What are cookies?</h2>
      <p>
        Cookies are small pieces of information or text that are issued to your
        computer when you visit a website and are used to store or track
        information about your use of the site.
        <br />
        <br />
        Ripspot uses both session-based and persistent-based cookies.
        Session-based cookies exist only during your web session and expire when
        you close your internet browser. Persistent-based cookies are files that
        stay in one of your browser’s subfolders until you delete them manually
        or your browser deletes them based on the duration period contained
        within the persistent cookie’s file.
        <br />
        <br />
        Our websites use cookies in order to offer a more tailored experience in
        the future, by understanding and remembering an individual’s particular
        browsing preferences, and for marketing purposes.
        <br />
        <br />
        Many cookies on our websites perform essential functions and enable
        users to move around our websites and use their features; for example,
        when users log in to their account, they remember the information in the
        account. We refer to these cookies in this policy as “strictly
        necessary. “
        <br />
        <br />
        Other cookies are not essential but they help us understand how our
        websites are used by visitors, so that we can improve what we do online.
        We use analytics tools for this. They look at things like how long our
        websites take to load, how they are used, and what information visitors
        to our sites look at most. This helps us to improve the way our websites
        work, for example, by ensuring that users find what they are looking for
        easily. They also help identify any parts of our websites that aren’t
        working as well as they should – so we can fix things and make our
        websites better for users. We refer to these cookies in this policy as
        “performance. “
        <br />
        <br />
        Additional cookies allow our websites to recognize you when you return
        to our websites. This enables us to remember choices that you make (such
        as user name, language or the region you are in) and provide enhanced,
        more personal features. We refer to these cookies in this policy as
        “functionality.” Some cookies are for marketing purposes and we refer to
        these cookies in this policy as “marketing.”
      </p>
      <h2>Cookies Used On This Website</h2>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>
                  <span>Name</span>
                </strong>
              </p>
            </td>
            <td>
              <p>
                <strong>
                  <span>Cookie Type</span>
                </strong>
              </p>
            </td>
            <td>
              <p>
                <strong>
                  <span>Description</span>
                </strong>
              </p>
            </td>
            <td>
              <p>
                <strong>
                  <span>Persistence</span>
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>Google</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
            <td>
              <p>
                <span>Site Analytics, media tracking, Re-Captcha</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>Stripe</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
            <td>
              <p>
                <span>Payment processing</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>Vimeo</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
            <td>
              <p>
                <span>Video content host</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span>Mapbox</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
            <td>
              <p>
                <span>Mapping technology for pin creation</span>
              </p>
            </td>
            <td>
              <p>
                <span>&nbsp;</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Do Not Track</h2>
      <p>
        While Ripspot attempts to honor do not track (“DNT”) instructions we
        receive from your browser, we cannot guarantee that Ripspot will always
        respond to such signals, in part because of the lack of common industry
        standard for DNT technology. We continue to monitor developments in DNT
        technology and stay apprised of DNT industry standards as they evolve.
      </p>
      <h2>Web Beacons</h2>
      <p>
        Web beacons (also known as internet tags, pixel tags, and clear GIFs)
        are clear electronic images that can recognize certain types of
        information on your computer, such as the type of browser used to view a
        website page, when you viewed a particular site linked to the Web
        beacon, and a description of a site tied to the Web beacon. Certain
        pages on our Website may contain such Web beacons, which Ripspot uses to
        operate and improve the Website.
      </p>
      <h2>IP Addresses</h2>
      <p>
        An IP address is a unique identifier that certain electronic devices use
        to identify and communicate with each other on the Internet. When you
        visit our Website, we may view the IP address of the device you use to
        connect to the Internet. We use this information to determine the
        general physical location of the device and the geographic regions of
        our visitors.
      </p>
      <h2>Your Choices</h2>
      <p>
        You can stop cookies being downloaded to your computer by selecting the
        appropriate settings on your browser. Most browsers will tell you how to
        stop accepting new cookies, how to be notified when you receive a new
        cookie, and how to disable existing cookies. You can find out how to do
        this for your particular browser by clicking “help” on your browser’s
        menu or by visiting{' '}
        <a href="https://allaboutcookies.org">www.allaboutcookies.org</a>.
        Please note that without cookies you may not be able to take full
        advantage of our Website features.
      </p>
      <h2>Google Analytics</h2>
      <p>
        Google Analytics is used to collect and process data on our websites.
        Google Analytics collects information such as how often users visit this
        site, what pages they visit when they do so, and what other sites they
        used prior to coming to this site. The information obtained from Google
        Analytics is only used to improve this site. Google Analytics collects
        only the IP address assigned to you on the date you visit this site,
        rather than any other identifying information. Google Analytics plants a
        permanent cookie on your web browser to identify you as a unique user
        the next time you visit this site but this cookie cannot be used by
        anyone but Google. To find out more information about Google Analytics
        visit: “How Google uses data when you use our partners’ sites or apps”,
        (located at{' '}
        <a target="_blank" href="https://google.com/policies/privacy/partners/">
          www.google.com/policies/privacy/partners/
        </a>
        ). To opt out download the Google Analytics opt-out browser add-on,
        available at{' '}
        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
          https://tools.google.com/dlpage/gaoptout.
        </a>
      </p>
      <h2 id="_How_to_Contact">How to Contact Us</h2>
      <p>
        To ask questions or comment about our information practices, please
        contact us at:
      </p>
      <p>
        Email: support@ripspot.com
        <br />
        Mail: Ripspot LLC.
        <br />
        c/o Privacy
        <br />
        PO Box 4262
        <br />
        Portland, ME 04101
        <br />
      </p>
    </Container>
  </ThemeProvider>
);
CookiesNotice.propTypes = {};

// const mapStateToProps =  props => ({});
const withConnect = connect();
export default compose(memo, withConnect)(CookiesNotice);

const Container = styled.div`
  color: ${theme.colors.Primary};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => theme.styles.middle}
  max-width: 680px;
  margin: 0 auto;
  padding: 2em 1em;
  padding-bottom: 10em;
  h1 {
    color: black;
    font-size: 4.2em;
    margin-top: 2em;
  }
  h2 {
    color: black;
    margin-top: 2em;
    align-self: flex-start;
  }
  h3 {
    font-size: 2em;
    margin-top: 1em;
    color: black;
    align-self: flex-start;
  }
  p {
    font-size: 1.7em;
    color: black;
    margin: 1em 0;
    align-self: flex-start;
    &:first-of-type {
      margin-top: 2.5em;
    }
  }
  a {
    text-decoration: underline;
  }
  ul {
    align-self: flex-start;
    padding-left: 3em;
    list-style: disc;
    li {
      font-size: 1.7em;
      color: black;
      margin: 0.5em 0;
      font-family: 'Montserrat', sans-serif;
      line-height: 1.5em;
      p {
        font-size: inherit;
        margin: 0 !important;
      }
    }
  }
  table {
    margin-top: 2em;
    td {
      border: 1px solid black;
    }
    td {
      padding: 5px 2px;
    }
    p {
      margin: 0 !important;
    }
  }
`;
